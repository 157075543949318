import React from 'react'
import Layout from "../components/App/Layout"
import NavbarThree from "../components/App/NavbarThree"
import Footer from "../components/App/Footer"
import SDCbenchInter from '../components/ServiceDetails/Sb1benchInter'
import RelatedServices from '../components/ServiceDetails/RelatedServices'
import ProjectStartArea from '../components/ServiceDetails/ProjectStartArea'

 
const Interactive = ({location}) => {
    return (
        <Layout path={location.pathname}>
            <NavbarThree />
            <SDCbenchInter />
            <RelatedServices />
            <ProjectStartArea />
            <Footer />
        </Layout>
    );
}

export default Interactive;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;